import React from 'react';
import '../../App1.css';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup, GeoJSON } from 'react-leaflet';
import { Icon} from "leaflet";
import mapData from './route.json'
import { useHistory } from 'react-router-dom';

export default function Services() {
  
  const history = useHistory();

  const handleButtonClick = () => {
    history.push("/gallery");
  };

  const customIcon = new Icon({
    iconUrl: require("../../images/location-pin.png"),
    iconSize: [38, 38]

  })

  const markers = [
    {
      geocode: [45.9763, 13.67749],
      popUp: "Vrh skabrijela 646m",
      title: "Psiho na delu",
      image: require("../../images/stolp.jpg")

    },
    {
      geocode: [45.98386, 13.66505],
      popUp: "Startno parkirno mesto Prevala"
    },
    {
      geocode: [45.9602, 13.64768],
      popUp: "Startno parkirno mesto Nova Gorica"
    }

  ];
  
  return (
   <MapContainer center={[45.976, 13.6775]} zoom={13}>
     <TileLayer
      
     // attribution="Google Maps"
      // url="http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}" // regular
      // url="http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" // satellite
     //url="http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}" // terrain
     // maxZoom={20}
     // subdomains={["mt0", "mt1", "mt2", "mt3"]}
      
      attribution='You are the boss'
       //url="https://google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}" // satellite

      url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
     />
      <GeoJSON data={mapData.features} />

       
     {markers.map(marker => (
       <Marker position={marker.geocode} icon={customIcon}>
         <Popup>
          {marker.popUp}
          <p>{marker.text}</p>
          
          <button onClick={handleButtonClick}>
          <img className="img-popup" src={marker.image} alt="weko" />
          </button>
         </Popup>
       </Marker>
     ))
     }
   </MapContainer>
  );
}

