export const Media = [
    {type: 'video', url: require('./video-01.MP4')},
    {type: 'image', url: require('./image-01.jpg')},
    {type: 'image', url: require('./image-02.jpg')},
    {type: 'image', url: require('./image-03.jpg')},
    {type: 'image', url: require('./image-04.jpg')},
    {type: 'image', url: require('./image-05.jpg')},
    {type: 'image', url: require('./image-06.jpg')},
    {type: 'image', url: require('./image-07.jpg')},
    {type: 'image', url: require('./image-08.jpg')},
    {type: 'image', url: require('./image-09.jpg')},

]