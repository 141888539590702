import React from 'react';
 import Navbar from './components/Navbar';
import './App.css';
import './App1.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Zgodovina from './components/pages/Zgodovina';
import SignUp from './components/pages/SignUp';
import ImageSlider from './components/pages/ImageSlider';
import Pohodnistvo from './components/pages/Pohodnistvo'
import Gallery from './components/pages/Gallery'
import { SliderData } from './components/pages/SliderData';
import Kontakt from './components/pages/kontakt'

function App() {
 
  return (
   <>
      <Router>
      <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/services' component={Services} />
          <Route path='/zgodovina' component={Zgodovina} />
          <Route path='/sign-up' component={SignUp} />
          <Route path='/imageslider' component={ImageSlider} />
          <Route path='/pohodnsvto' component={Pohodnistvo} />
          <Route path='/gallery' component={Gallery} />
          <Route path='/kontakt' component={Kontakt} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
