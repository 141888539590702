import React, {useState } from 'react'
import '../../App2.css';
import { Media }  from '../../images';


export default function Gallery() {
  const [file, setFile] = useState(null);
   return (
    <div style={{backgroundColor: '#7a6e66'}}>
     <div className='container'>
       <h1>Hriboteka</h1>
 
     <div className='media-container'>
       {
         Media.map((file, index) => (
           <div className='media' key={index} onClick={() => setFile(file)} >
            {
             file.type === 'image'
             ? <img src={file.url} alt="" />
             : <video src={`${file.url}#=0.001`} muted preload='metadata' />
            }
             </div>
         ))
       }
     </div>
 
     <div className='popup-media' style={{ display: file ? 'block' : 'none'}}>
      <span onClick={() => setFile(null)}>Zapri</span>
 
      {
       file?.type === 'video'
       ? <video src={file?.url} muted autoPlay controls />
       : <img src={file?.url} />
      }
 
     </div>
     </div>
     </div>
   )
 }