import React from 'react';
import '../../App.css';
import ImageSlider from './ImageSlider';
import { SliderData } from './SliderData';
import Typewriter from 'typewriter-effect';

export default function Products() {
  return ( 
    <div>
       
       <h1>
        <span>
        <Typewriter
  options={{
    strings: ['ZGODOVINA', 'SKABRIJELA', 'VOJNA', 'MIR'],
    autoStart: true,
    loop: true,
    delay: 75,
  }}
/>
        </span>
       </h1>
   <div>
   <p className='zgo-text'>Eno najhujših in najbolj krvavih bojišč na soški fronti, ki se je neizbrisljivo zapisalo v zgodovino 87. pehotnega polka, je bil Škabrijel. Svoje ime je ta 646 m visok hrib nad Solkanom dobil po nadangelu Gabrijelu, zavetniku cerkvice, ki je nekdaj stala na njem.
Med prvo svetovno vojno je sprva preko hriba in naprej proti Sveti Gori potekala rezervna obrambna črta avstro-ogrske vojske. Avgusta 1917, po 6. soški bitki, v kateri so Italijani zavzeli Gorico in Sabotin, se je območje znašlo na fronti ter bilo med 10. (12. maj–5. junij 1917) in zlasti 11. soško bitko (19. avgust–13. september 1917) prizorišče izjemno hudih bojev, še posebej po 24. avgustu, ko se je s strani italijanske vojske začel glavni napad na to strateško pomembno točko, ki bi v primeru osvojitve Italijanom omogočila preboj fronte in morda tudi zmago v vojni. Proti njegovemu vrhu so zato napotili številne može iz brigad Abruzzi, Arno, Campobasso, Elba, Ferrara, Foggia, Forli, Girgenti, Messina, Palermo, Pescara, Trapani in Treviso, med katerimi so bile tudi elitne enote arditov. Območje so pred njimi zavoljo hudih izgub branile številčno šibkejše avstro-ogrske enote: 14., 24., 50., 57. in 87. pehotni polk, 23. lovski bataljon, 23. strelski in 2. gorski strelski polk ter kraljevi ogrski 1., 3., 4. in 17. domobranski pehotni polk. Po skoraj enomesečnem bojevanju so se boji za vrh končali 13. septembra 1917 z neuspešnim italijanskim poskusom osvojitve.
Škabrijel velja po nekaterih navedbah za najbolj obstreljevano točko na soški fronti in mesto, kjer je padlo največ žrtev na kvadratni kilometer. Prav zaradi velikih izgub so mu Italijani nadeli ime Monte della morte – hrib smrti. Z besedami »… gora svetega Gabrijela, dom mrličev, groblja labirintov, grmada človeških kosti, spomenik zverinstva …« je omenjeni hrib v črtici Sovraštvo opisal tudi pisatelj France Bevk.
Po krvavih bojih na Škabrijelu avgusta in septembra 1917 so izgube 87. pehotnega polka, ki je tu nemara bojeval svoj najhujši boj, znašale 44 častnikov (od tega 2 padla, 20 ranjenih, 7 obolelih, 3 pogrešani in 12 ujetih) in 1.578 vojakov (od tega 131 padlih, 968 ranjenih, 236 obolelih, 126 pogrešanih in 117 ujetih). Kako hude so bile te izgube, pove podatek, da je polk takrat izgubil okoli 40 odstotkov častniškega kadra in 80 odstotkov vojakov.
Za izkazan pogum so mnogi preživeli pripadniki celjskega polka, ki so prej v boju prestali hude telesne in duševne napore, prejeli razna vojaška odlikovanja, poveljnik 18. pehotne brigade Wladimir Laxa pa je bil za uspešno obrambo položajev na Škabrijelu odlikovan z viteškim križcem reda Marije Terezije.
Padle so pokopali na več vojaških pokopališčih v neposredni bližini. Eno takšnih je bilo v kraju Ravnica, ki pa so ga v obdobju po drugi svetovni vojni uničili. Septembra 1997 je na njegovem mestu Društvo Goriških domoljubov postavilo spomenik padlim slovenskim vojakom na Škabrijelu, katerega avtor je Pavel Medvešček.
Gozd je sčasoma skoraj povsod prerasel nekoč od bojev povsem ogolel apnenčast hrib in zakril brazde, ki mu jih je zadala vojna ob Soči. Škabrijel je danes priljubljena izletniška točka in muzej na prostem s številnimi urejenimi potmi in Potjo miru, ki vodi mimo ostalin nekdanjega bojišča. Na vrhu sta postavljena kamnit spomenik padlim vojakom in razgledni stolp, s katerega se odpira pogled na vse strani.</p>
    </div>
  <ImageSlider slides={SliderData} />
    </div>
  )
}
