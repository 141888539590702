export const SliderData = [
    {
      image:'https://www.kamra.si/wp-content/uploads/2021/01/11_Foto_1_Skabrijel_20190531_105507-1490x1000.jpg'  
    },

    {
        image:'https://www.kamra.si/wp-content/uploads/2021/01/11_Foto_3_Skabrijel_20190513_120054-1486x1000.jpg'  
      },

      {
        image:'https://www.kamra.si/wp-content/uploads/2021/01/11_Foto_5_Skabrijel_20190513_120407-1434x1000.jpg'  
      },

      {
        image:'https://www.kamra.si/wp-content/uploads/2021/01/11_Foto_6_Skabrijel_20190513_120534-701x1000.jpg'  
      },

      {
        image:'https://www.kamra.si/wp-content/uploads/2021/01/11_Foto_8_Skabrijel_20190513_120723.jpg'  
      }
]