import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Spletni kažipot</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/pohodnistvo.jpg'
              text='Pohodništvo'
              label='Rekreacija'
              path='/services'
            />
            <CardItem
              src='images/img-skabi1.jpg'
              text='Zgodovina'
              label='Preteklost'
              path='/zgodovina'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/skabrijel-montainbike.jpg'
              text='Proga za mountain bike'
              label='Adrenalin'
              path='/services'
            />
            <CardItem
              src='images/tekmovanje-skabrijel.jpg'
              text='Tekmovanja'
              label='Adventure'
              path='/products'
            />
            <CardItem
              src='images/druzenje.jpg'
              text='Druženje'
              label='Adrenaline'
              path='/ImageSlider'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
